<template>
    <div id="loan-address">
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-1">Información del obligado solidario.</h3>
                <p>Contar con un obligado solidario te permite obtener mejores condiciones para tu crédito y puede responder por el pago del mismo.</p>
                <vs-divider></vs-divider>
            </div>
            <!-- content -->
            <div class="vx-col w-8/12">
                <div v-if="isMounted" class="main-form mt-2">
                    <vs-alert v-if="guaranteeIsRejected" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-0 mb-6" color="danger">
                    <span class="font-regular"><strong>Obligado Solidario Rechazado</strong><br>
                      <span>
                        El obligado solidario fue rechazado, para continuar con el proceso será necesario que actualices la información con un nuevo obligado solidario.
                      </span>
                    </span>
                  </vs-alert>
                    <div class="vx-row">
                        <div v-for="section in allSections" :key="section.id" :class="colClass">
                            <div class="vx-row">
                                <template v-for="f in section.fields">
                                    <!-- Campos automaticos -->
                                    <form-field :ref="`section_id_${section.id}`" v-if="isAutoFormField(f) && showFields(f.fname)" :key="f.id"
                                        :f="f" 
                                        :dataContainer="getContainerForField(section, f)"
                                        :collectionsRoot="collections" 
                                        :onChangeReceptor="onFieldChange"
                                        :hasBackofficeAccess="false" 
                                        :blockedByDocument="f.blockedByDocument"
                                        :evaluator="evaluateCondition" 
                                        :inputClassLarge="true"
                                        :countriesList="collections.countries" 
                                        :editableByBackoffice="true" />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mt-10">
                        <div class="vx-col w-full">
                            <!-- BOTONES -->
                            <div class="flex justify-end">
                                <vs-button class="mt-2 vs-button-dark"
                                    @click="saveSections(`section_id_${current_section}`)">Continuar</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vs-popup
            v-if="referenceDuplicated != null"
            :active.sync="showReferenceDuplicatedPropmt"
            title="Actualiza tu referencia">
            <div class="vx-row p-2 mb-2">
                <p class="p-2 bold">Tu nueva referencia debe ser distinta a la siguiente:</p>
                <div class="w-full">
                    <vs-list>
                        <vs-list-item title="Nombre">{{ referenceDuplicated.name }}</vs-list-item>
                        <vs-list-item title="Correo">{{ referenceDuplicated.email }}</vs-list-item>
                    </vs-list>
                </div>
            </div>
            <vs-divider></vs-divider>
            <div class="vx-row mb-2">
                <div :class="colReferencesClass">
                    <vs-input
                        @input="((val) => {newReference.name = newReference.name.toUpperCase() })"
                        class="w-full"
                        label="Nombre y Apellidos"
                        type="text"
                        :placeholder="requiredPlaceholder"
                        v-model.lazy="newReference.name"
                        v-validate="'required'"
                        :danger="hasError('r.name_1')"
                        :danger-text="errorText('r.name_1')"
                        :success="isSuccess('r.name_1')"
                        :name="`r.name_1`"/>
                        <small></small>
                </div>
                <div :class="colReferencesClass">
                    <label class="vs-input--label">Celular a 10 dígitos</label>
                    <div class="flex">
                        <vue-country-code 
                            :name="`dialCode_1`" 
                            class="vs-custom"
                            data-vv-name="dialCode" 
                            :enableSearchField="true"
                            v-model="newReference.country_calling_code"
                            searchPlaceholderText="Buscar código de país"
                            :enabledCountryCode="true" 
                            @onSelect="onSelect($event)"
                            :preferredCountries="['MX']" 
                            :danger="hasError(`dialCode_1`)"
                            :danger-text="errorText(`dialCode_1`)"
                            :success="isSuccess(`dialCode_1`)">
                        </vue-country-code>
                        <vs-input class="w-full" 
                            label="" 
                            :name="`r.phone_`" 
                            type="tel"
                            v-validate="'required|numeric'"
                            v-model.lazy="newReference.phone"
                            :danger="hasError('r.phone_')"
                            :danger-text="errorText('r.phone_')"
                            :success="isSuccess('r.phone_')" 
                            icon-pack="feather"
                            placeholder="(Requerido) Ingresa 10 dígitos" />
                    </div>
                </div>
                <div :class="colReferencesClass">
                    <vs-input 
                        class="w-full" 
                        label="Correo electrónico"
                        :name="`r.email_`" 
                        v-validate="emailRules"
                        v-model.lazy="newReference.email"
                        :danger="hasError('r.email_')" 
                        :danger-text="errorText('r.email_')"
                        :success="isSuccess('r.email_')" 
                        icon-pack="feather"
                        :placeholder="emailPlaceholder" />
                </div>
                <div :class="colReferencesClass">
                    <label for="" class="vs-input--label">Tipo de Relación</label>
                        <v-select
                            label="text"
                            :name="`r.relation_type_`"
                            class="vs-custom w-full mb-5"
                            v-model.lazy="newReference.relationType"
                            v-validate="'required'"
                            :options="getOptionsForSelect"
                            :reduce="text => text.value"
                            :clearable="false"
                            :searchable="false"
                            placeholder="Seleccione una opción">
                        </v-select>
                </div>
                <div class="vx-col sm:w-full md:w-1/2">
                    <vs-button color="black" @click.stop="saveReferences()">Guardar referencia</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
import dateHelper from '@mixins/dateHelper';
import stpHelper from "@mixins/stpHelper";

const sectionsToContainers = [
    {
		id: 28, containers: [
			{ db: 'business_profiles', path: 'client.user.business' },
			{ db: 'business_credit_profiles', path: 'client.user.business.credit_profile' }
		]
	},
	{
		id: 14, containers: [
			{ db: 'personal_profiles', path: 'finance.guarantee.guarantee_profile.personal' },
			{ db: 'phones', path: 'finance.guarantee.guarantee_profile.personal.phone' },
			{ db: 'users', path: 'client.user' },
			{ db: 'personal_credit_profiles', path: 'finance.guarantee.guarantee_profile.personal.credit_profile' },
			{ db: 'projects', path: 'base' },
			{ db: 'clients', path: 'client' }
		]
	},
    {
		id: 19, containers: [
			{ db: 'guarantee_profiles', path: 'finance.guarantee.guarantee_profile' },
			{ db: 'personal_profiles', path: 'finance.guarantee.guarantee_profile.personal' },
			{ db: 'personal_credit_profiles', path: 'finance.guarantee.guarantee_profile.personal.credit_profile' },
            { db: 'phones', path: 'finance.guarantee.guarantee_profile.personal.phone' },
		]
	},
];

const genderDefs = [
  {k: 1, v: "HOMBRE"},
  {k: 2, v: "MUJER"},
];


export default {
    name: "ApplicantAddress",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper, dateHelper, stpHelper],
    components: {
        FormField
    },
    props: {
        project: Object
    },
    data: () => ({
        base: {},
        isMounted: false,
        colClass: "vx-col w-full mb-5",
        emailRules: 'required|email',
        emailPlaceholder: 'ejemplo@mail.com',
        requiredPlaceholder: '(Requerido)',
        colReferencesClass: 'vx-col sm:w-1/2 md:w-1/10 mb-5',
        pmSections: [28],
        pfSections: [19],
        allSections: [],
        formSections: [],

        allSections: [],
        formSections: [],

        documentsFields: [],
        documentsStates: [],

        validatedDocuments: [],
        allDocumentFields: [],
        marriedMaritalStatusId: 2,
        collections: {
            countries: [],
            genders: genderDefs
        },
        slas: {
            min_guarantee_age: null,
            max_guarantee_age: null,
        },
        referenceDuplicated: null, 
        newReference: {
            name: null,
            phone: null,
            country_calling_code: null,
            phone_id: null,
            email: null,
            relation_with_applicant: null,
        }, 
        showReferenceDuplicatedPropmt: false,
        relationTypes: [],
        getOptionsForSelect: [],
    }),
    async mounted() {
        this.isMounted = false;
        this.base = this.project;
        await this.getCollections();
        await this.getSections();
        this.guarantee.relation_with_applicant = this.guarantee.relation_with_applicant == 0 ? null : this.guarantee.relation_with_applicant;
        this.isMounted = true;
    },
    computed: {
        isMoral() {
            return this.base.client.user.business_profile_id != null;
        },
        projectId() {
            return this.base.id;
        },
        current_section() {
            return this.isMoral ? this.pmSections[0] : this.pfSections[0];
        },
        guarantee() {
            return this.base.finance.guarantee.guarantee_profile;
        },
        guaranteeIsRejected() {
            return this.guarantee.rejected_date != null;
        },
        personal() {
            return this.base.finance.guarantee.guarantee_profile.personal;
        },
        business() {
            return this.base.client.user.business;
        },
        client_should_be_married(){
            return this.personal.marital_status == this.marriedMaritalStatusId;
        },
        is_guarantee_personal_completed() {
            return this.personal.curp != null && this.personal.rfc != null && this.personal.phone_id != null && this.personal.first_name != null && this.personal.last_name_1 != null;
        },
        should_block_phone() {
            return this.base.status >= 3 ;
        },
        guaranteeProfile() {
            return this.base.finance.guarantee.guarantee_profile;
        },
        guaranteeName() {
            return this.isMoral ? this.business.name : this.personal.first_name + " " + this.personal.last_name_1;
        },
        guaranteeMail() {
            return this.isMoral ? this.business.name : this.personal.first_name + " " + this.personal.last_name_1;
        },
    },
    methods: {
        onSelect(dialCode) {
            this.newReference.country_calling_code = dialCode.dialCode;
        },
        async validate_client_unique_phone() {
            try {

                const payload = {
                    phone: this.personal.phone.phone
                };
        
                const res = await axios.post(`/api/v1/forms/validate-phone-in-project/${this.base.id}`, payload);
                if(res.data == 1) {
                    this.missingFieldsNotif(null, "El teléfono ya está registrado dentro de la solicitud, el teléfono debe ser diferente al del solicitante y referencias. ");
                    return false;
                }

                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        showFields(fname) {
            return (
                fname == "first_name" 
                || fname == "second_name"
                || fname == "last_name_1"
                || fname == "last_name_2"
                // || fname == "citizenship"
                // || fname == "gender"
                || fname == "birth_date"
                // || fname == "curp"
                // || fname == "rfc"
                || fname == "country_calling_code"
                || fname == "phone"
                // || fname == "origin_country"
                || fname == "email"
                || fname == "relation_with_applicant"
            )
        },
        async getSections() {
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
            this.allSections = res.data.sections;

            await this.asyncForEach(this.allSections, async (s) => {
                s.containers = this.getContainersForSection(s.id);
                s.documentFields = [];

                await this.asyncForEach(s.fields, async (f) => {
                    this.formFieldSetter(f, this);
                });
            });
        },
        getContainersForSection(section_id) {
            let c = sectionsToContainers.find(f => f.id == section_id);
            if (!c) {
                this.warn("Missing containers definition for section " + section_id);
                return null;
            }
            else {
                let sectionContainers = [];
                c.containers.forEach(cc => {
                    let con = this.setContainerFromDef(cc);
                    sectionContainers.push({ db: cc.db, path: cc.path, container: con });
                })
                return sectionContainers;
            }
        },
        async getCollections(){
            try {
                let collectionsObjects = ['countriesList'];

                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                this.collections.countries = res.data.countriesList;

                let slasObjects = ['min_guarantee_age', 'max_guarantee_age'];
                let slasParams = "with[]=" + slasObjects.join("&with[]=");
                let slasRes = await axios.get(`/api/v1/forms/getSlasValues?${slasParams}`);
                this.slas = slasRes.data;

                let res1 = await axios.get("/api/v1/forms/getProjectRelatedProfilesRelationTypes");
                this.relationTypes = res1.data;

                let options = [];
                this.relationTypes.forEach(opt => {
                    options.push({value: opt.type, text: opt.name}); //conversion a sintaxis de vx-select 
                })
                this.getOptionsForSelect = options;
            }
            catch (e) {
                console.log(error);
                this.warn(e);
                this.failed = true;
            }
        },
        // GUARDAR
        async saveSections(gslug) {
            // let uniqueCurp = await this.validate_unique_curp(this.personal.curp, this.personal.id);
            // if(!uniqueCurp){
            //     this.sendIdentifiersNotification(
            //         {
            //             user_id: this.guarantee.id,
            //             loan_id: this.projectId,
            //             role: "guarantee",
            //             flow: "CURP",
            //             id: this.personal.curp
            //         }
            //     );
            //     return;
            // }

            let result = await this.runValidations(gslug);
            if (!result) {
                if (!this.skipMissingWarnings) {
                    this.missingFieldsNotif();
                }
            } else {
                this.showLoading(true, "Guardando información...");
                //guardar solo la informacion que se ha modificado
                this.basePayload = this.collectFormData(gslug);
                if (this.objectIsEmpty(this.basePayload)) {
                    this.showLoading(false);
                    this.showGuaranteeAlert();
                    await this.requestGuaranteeAuthorization();
                    // this.createStpAccount(this.base.client.id);
                    // await this.checkIfCanUpdateLoanOnboardingStep(this.base.id);
                    await this.$emit("saved", 0);
                    await this.$emit("updated", 1);
                    return;
                }

                this.basePayload.id = this.base.id;
                this.basePayload.finance.guarantee.guarantee_type = { value: 1 }
                try {
                    // ejecutar guardado
                    this.injectAccountMetadataToPayload(this.basePayload);
                    await axios.put(`/api/v2/projects/${this.projectId}/deepUpdate`, this.basePayload);
                    // solicitar la informacion actualizada del modelo
                    this.showLoading(false);
                    this.showGuaranteeAlert();
                    await this.requestGuaranteeAuthorization();
                    await this.$emit("saved", 0);
                    await this.$emit("updated", 1);
                    this.saveSuccessNotif();
                }
                catch (error) {
                    this.showLoading(false);
                    this.warn(error);
                    this.failedOperationNotif(null, null);
                }

                // se crea la cuenta stp
                try {
                    await this.createStpAccount(this.base.client.id);
                } catch (error) {
                    console.log("No se generó la cuenta STP");
                }

            }
        },
        showGuaranteeAlert() {
            this.$vs.dialog({
                color: 'success',
                title: 'Notificaremos a tu obligado.',
                text: `Enviaremos un correo a tu Obligado Solidario (${this.guaranteeName} - ${this.guaranteeProfile.email}) en donde se le solicitará verifique su identidad y autorice la consulta de historial crediticio.
                        \n Por favor comunícate con el/ella para que no se extrañe de este correo y nos ayude en tu proceso de solicitud de crédito.`,
                acceptText: "Continuar",
            });
        },
        async requestGuaranteeAuthorization() {
            try {
                const res = await axios.post(`/api/loan-onboarding/post/${this.projectId}/guarantee-notification`);
            } catch (error) {
                console.log(error);
            }
        },
        async runValidations(gslug) {
            // validar componentes tipo FormField
            let res1 = await this.validateFormFields(gslug);
            if (!res1) {
                return false;
            }

            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return false;
            }

            // Valida que el correo del OS no sea igual que el del solicitante
            let emailIsUnique = await this.validate_is_guarantee_email_equal_to_applicants();
            if(!emailIsUnique) {
                /**
                 * Buscar la referencia a la que se parece
                 * Preguntar que esa es una referencia y que si desea continuar con el como OS 
                 * Si no, que cambie el correo de os.
                 * Sí decide cambiar al OS tiene que cambiar la referencia y dar de alta una nueva y el form de os lo deja igual.
                 */
                return false;
            }

            // Valida que el correo del OS no sea igual que el del solicitante
            let phoneValidation = await this.validate_client_unique_phone();
            if(!phoneValidation) {
                return false;
            }

            // retornar true si todas las validaciones fueron positivas
            return true;
        },

        async getDuplicatedReference() {
            // applicant/reference-duplicated
            try {
                const res = await axios.post(`/api/applicant/reference-duplicated`, {
                    applicant_id: this.base.client.id,
                    email: this.base.finance.guarantee.guarantee_profile.email
                });

                // console.log(res.data);
                this.referenceDuplicated = res.data;

                if(this.referenceDuplicated != null) {
                    this.$vs.dialog({
                        type: 'confirm',
                        color: 'warning',
                        title: 'Tu obligado solidario ya es una referencia.',
                        text: `El obligado solidario ingresasdo ya es una referencia, si deseas continuar con éste obligado solidario debes agregar una referencia adicional.`,
                        acceptText: "Aceptar",
                        cancelText: 'Cancelar',
                        accept: this.addNewReference,
                        cancel: null
                    });
                }


            } catch (error) {
                console.log(error);
            }
        },
        addNewReference() {
            this.showReferenceDuplicatedPropmt = true;
        },
        async saveReferences() {
            try {
                if (this.newReference.name == null || this.newReference.phone == null || this.newReference.email == null || this.newReference.relationType == null) {
                    this.missingFieldsNotif();
                    return;
                }

                this.newReference.phone_id = this.referenceDuplicated.phone_id;

                this.showLoading(true, "Guardando referencia...");
                const res = await axios.post(`/api/applicant/${this.ApplicantId}/save-reference/${this.referenceDuplicated.id}`, {
                    reference: this.newReference
                });
                this.showReferenceDuplicatedPropmt = false;
                this.showLoading(false);
                this.saveSuccessNotif();
                
            } catch (error) {
                this.showLoading(false);
                this.failedOperationNotif();
                console.log(error);
            }
        },
        collectFormData(gslug) {
            let payload = {};
            const sectionId = gslug.substr(11);

            let section = this.allSections.find(s => s.id == sectionId);
            section.containers.forEach(cc => {
                let fields = this.validatedFields.filter(vf => vf.db == cc.db);
                if (fields.length < 1) {
                    return;
                }

                if (cc.path == "base") {
                    fields.forEach(f => {
                        payload[f.fname] = cc.container[f.fname];
                    });
                }
                else {
                    let obj = null;

                    obj = { id: cc.container.id };
                    fields.forEach(f => {
                        obj[f.fname] = cc.container[f.fname];
                    });

                    // ajuste para objetos tipo array
                    this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
                }
            });
            return payload;
        },
        async validateFormFields(refGroup) {
            let allValid = true;
            this.validatedFields = [];
            let grefs = this.$refs[refGroup];
            let f;
            for (let p in grefs) {
                f = grefs[p];
                let r = await f.checkForValidDirty();
                if (r.valid == false) {
                    allValid = false;
                }
                else if (r.dirty == true) {
                    let sp = f.specialValidation();
                    // console.log(sp);
                    if (sp != null && sp.method in this) {
                        let res = await this[sp.method]();
                        let n = f.rawFieldName;
                        if (!res) {
                            if (f.db_name === "rfc" && res.length === 0) {
                                continue;
                            }

                            if(sp.method === "validate_is_guarantee_email_equal_to_applicants") {
                                await this.getDuplicatedReference();
                            }
                            this.skipMissingWarnings = true;
                            allValid = false;
                            if(sp.custom_error){
                                let err = this[sp.custom_error]();
                                f.setError(err);
                                this.errorNotif(n, err, 10000);
                            } else {
                                f.setError(sp.error);
                                this.errorNotif(n, sp.error, 10000);
                            }
                            continue;
                        }
                    }
                    this.validatedFields.push(r);
                }
            }
            return allValid;
        },
        getContainerForField(section, f) {
            let c = section.containers.find(sc => sc.db == f.db_table);
            if (!c) {
                this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
                return null;
            }
            else {
                return c.container;
            }
        },
        setContainerFromDef(c) {
            let container = this.nestedFieldValue(this.base, c.path);
            if (Array.isArray(container)) {
                let ac = null;
                if (c.arrayDefs) {
                    if (c.arrayDefs.getFirst == true) {
                        ac = container[0];
                    }
                    else if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval](container);
                    }
                }
                if (!ac) {
                    if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                    else { ac = {} }
                    container.push(ac);
                }
                return ac;
            }
            else {
                if (c.path == 'bank_accounts_bank') {
                    let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
                    let nbankAcc = accs[0];
                    return nbankAcc.bank;
                }
                if (container == null && c.arrayDefs) {
                    let ac = null;
                    if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval]();
                        return ac;
                    }
                    if (!ac) {
                        if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                        else { ac = {} }
                        container = ac;
                    }
                }
                return container;
            }
        },
        client_curp_conditions_changed(){
            this.trigger_curp_conditions_changed(this.personal);
        },
        PF_PFAE_guarantee_profile_curp_conditions_changed(){
            this.trigger_curp_conditions_changed(this.personal);
        },

        trigger_curp_conditions_changed(obj){
            let c = obj["curp"];
            if(!c){ return; }
            if(c.length >= 10){
                let yy = c.slice(4,6);
                let mm = c.slice(6,8);
                let dd = c.slice(8,10);

                let date = this.getDateFromInputs(yy, mm, dd);
                if(date === false){
                obj["birth_date"] = null;
                }
                else{
                obj["birth_date"] = date;
                }
            }
            else{
                obj["birth_date"] = null;
            }

            if(c.length >= 11){
                let g = c.slice(10, 11);
                if(g == "H"){
                obj["gender"] = 1;
                }
                else if(g == "M"){
                obj["gender"] = 2;
                }
                else{
                obj["gender"] = null;
                }
            }
            else{
                obj["gender"] = null;
            }
        },
        /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },
        /* on change receptor */
        onFieldChange(method) {
            if (method != null && method in this) {
                this[method]();
            }
        },
        /* validaciones especiales */
        validate_client_age_legal(){
            let years = this.calculateAgeFromDate(this.personal.birth_date);
            let min = parseInt(this.slas.min_guarantee_age);
            let max = parseInt(this.slas.max_guarantee_age);
            return years >= min && years <= max;
        },
        client_birthdate_invalid(){
            return `El obligado solidario debe tener entre ${this.slas.min_guarantee_age} y ${this.slas.max_guarantee_age} años`;
        },
        async validate_client_unique_rfc(){
            return await this.validate_unique_personal_rfc(this.personal.rfc, this.personal.id)
        },
        async pf_validate_guarantee_profile_unique_curp(){
            return await this.validate_unique_curp(this.personal.curp, this.personal.id);
        },
        async validate_client_unique_curp(){
            return await this.validate_unique_curp(this.personal.curp, this.personal.id)
        },
        async validate_is_guarantee_email_equal_to_applicants() {
            return !await this.isEmailDuplicatedInProject(this.base.finance.guarantee.guarantee_profile.email, this.base.id, {
                tableName: 'guarantee_profiles',
                key: 'id',
                value: this.base.finance.guarantee.guarantee_profile.id
            });
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        validate_guarantee_age(){
            let years = this.calculateAgeFromDate(this.personal.birth_date);
            let min = parseInt(this.slas.min_guarantee_age);
            let max = parseInt(this.slas.max_guarantee_age);
            return years >= min && years <= max;
        },
        guarantee_birthdate_invalid(){
            return `El obligado solidario debe tener entre ${this.slas.min_guarantee_age} y ${this.slas.max_guarantee_age} años`;
        },
    }
}
</script>